import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getFopReportFilter } from "../../actionMethods/actionMethods";

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getAttendanceTime,getRegularEmployeeMap } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";



const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function AttendanceTime (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const indonesiaTimeZone = 'Asia/Jakarta';  
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
      
  const [loader,SetLoader]= useState(true);


const columns = [
  { headerName: 'Name',title:"Name", dataIndex:"name", field: 'name' },
  { headerName: 'Worker ID',title:"Worker ID",dataIndex:"worker_id", field: 'worker_id' },
  { headerName: 'Agency',title:"Agency",dataIndex:"agency", field: 'agency' },
];

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }



useEffect(()=>{
  let reportHead=[]
async function filterResultHandler(){
    try{
        if(props.filterActive){
          let requestBody = props.filterPassData;
        
            
        
        let startDate = new Date(requestBody.start_date);
        let endDate = new Date(requestBody.end_date);
          SetLoader(true);
          let mapList =  await getOnBoardEmp(userSession,org_id,requestBody.start_date,requestBody.end_date,sub_org_id).then(res=> {
            console.log(res);
            let all=[]
            if(res.data.daily){
              all.push(...res.data.daily);
            }
            if(res.data.regular){
              all.push(...res.data.regular);
            }
            return all;
          });

          // let mapList = [...mapListUnformated.daily_worker_ids,...mapListUnformated.regular_worker_ids]

          
          let empUnFormated  = await getAttendanceTime(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date,requestBody.check_in,requestBody.check_out).then(res=>res);      
          let empList =[];
          console.log("empUnFormated",empUnFormated)
          if(empUnFormated && empUnFormated.status === "302"){

          }else{
          empUnFormated.forEach(el=>{
            
            let detail = mapList.find(item => item.worker_id == el.worker_id && item.check_in ===  el.actual_check_in.replaceAll(' GMT',""));
            console.log("el",el,detail);
            if(detail){
                          el.department=  detail.department_name?detail.department_name:"-"	;
            el.function  = detail.sub_department_name	?detail.sub_department_name	:"";
            el.agency = detail.agency;              
            }else{
              el.department=  ' - ';
              el.function  = ' - ';
              el.agency = ' - ';
            }

            // if(el.Work_time_status === "Over-time" && el.over_time=="" ){
            // if(el.Work_time_status === "Over-time" ){
            //   el.Work_time_status = "Non Checkout";
            // }

            // return el
            empList.push(el);
          })

          }

          if(props.filterPassData.status){
            empList  = empList.filter(el=> props.filterPassData.status.indexOf(el.Work_time_status) > -1 );
          }
         

          if(empList.length > 0){
            SetFilterData(empList);
         
          }else{
            SetFilterData([]);
            toast.error("Something Went Wrong While Fetching Attendance Time");
          }
          
              SetLoader(false);
            if(empList.status===200){
              
            }else{
              
              SetLoader(false);
            }

          }
    }catch(error) {
            console.log("error",error)
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);


useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(filterData);
  }
},[props.exportEnable,filterData])

 const generateExcelFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    
        await generateSheet(workbook, "Attendance Time",data ,imageId);
    
  const fileName = 'AttendanceTimeReport '+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName,data, logoID) => {
    // Add a new worksheet
    const worksheet = workbook.addWorksheet(sheetName);

     worksheet.addImage(logoID, {
      tl: { col: 0, row: 1 },
      ext: { width: 153, height: 34 },
    });

    worksheet.addRow([])    
    worksheet.addRow([])    
    worksheet.addRow([])
    worksheet.addRow([])    
    worksheet.mergeCells('A1:B4');    
    worksheet.addRow([])

   worksheet.addRow(['Date', moment(props.filterPassData.date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
   worksheet.addRow(['Attendance Report', "Time"]).getCell(2).font = { bold: true };
   worksheet.addRow(['Site', userDetails.org_name]).getCell(2).font = { bold: true };
   worksheet.addRow(['Filter Check-In', props.filterPassData.check_in]).getCell(2).font = { bold: true };
   worksheet.addRow(['Filter Check-In', props.filterPassData.check_out]).getCell(2).font = { bold: true };

   worksheet.addRow([]);
   worksheet.addRow([]);
    
   const tableData =    data.map(item => [
      moment(item.work_day.replaceAll(" GMT","")).format("DD-MM-YYYY"),
      item.name, item.worker_id,
      item.agency,
      item.department,
      item.function,
      moment(item.plan_check_in.replaceAll(" GMT","")).format('DD MMM YYYY HH:mm'),
      moment(item.actual_check_in.replaceAll(" GMT","")).format('DD MMM YYYY HH:mm'),
      moment(item.plan_check_out.replaceAll(" GMT","")).format('DD MMM YYYY HH:mm'),
      moment(item.actual_check_out.replaceAll(" GMT","")).format('DD MMM YYYY HH:mm'),
      item.plan_checkin_status,
      item.plan_checkout_status,
      item.plan_work_hours,
      item.actual_work_hours,
      item.over_time==="" ||  item.over_time==="00:00:00"?" - ":item.over_time,
      item.Work_time_status

    ]);
    worksheet.addTable({
      name: sheetName.replaceAll(' ', '_'),
      ref: `A12`,

      columns: [{ name: 'Work Date' },{ name: 'Name' }, { name: 'Worker ID'}, { name: 'Agency'}, { name: 'Department'}, { name: 'Function'}, { name: 'Plan Check-In'}, { name: 'Actual Check-In'},
      { name: 'Plan Check-Out' },{ name: 'Actual Check-Out' },{ name: 'Plan Check-In Status'}, { name: 'Plan Check-Out Status'},
      { name: 'Plan Work Hours' },{ name: 'Actual Work Hours' },
      {name:"Time"},{name:"Status"}
       ],
      rows: tableData,
    });
    const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));

    worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v?v.toString().length:1);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength + 2;
    });

    tableData.forEach((row, rowIndex) => {
    const timeDifferenceCell = worksheet.getCell(`O${rowIndex + 13}`); // Adjust the index if necessary
    const timeDifferenceValue = timeDifferenceCell.value;

    if (timeDifferenceValue.startsWith('-')) {
      timeDifferenceCell.font = { color: { argb: 'FFFF0000' } }; // Red color for negative values
    } else {
      timeDifferenceCell.font = { color: { argb: '#018001' } }; // Green color for positive values
    }
  });

  worksheet.getTable(sheetName.replaceAll(' ', '_')).commit();



    table.commit();
  };
  

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};


function timeStringToMilliseconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return ((hours * 60 + minutes) * 60 + seconds) * 1000;
}

function millisecondsToTimeString(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}


// function valueRenderTime(params){
//         var op =document.createElement('div');

//         const actualWorkHoursMs = timeStringToMilliseconds(params.data.actual_work_hours);
// const planWorkHoursMs = timeStringToMilliseconds(params.data.plan_work_hours);

// const differenceMs = actualWorkHoursMs - planWorkHoursMs;
// const differenceTimeString = millisecondsToTimeString(Math.abs(differenceMs));
// const differenceSign = differenceMs >= 0 ? '' : '-';


//         var eGui = document.createElement('div');
          
//         if(differenceSign === "-"){
//           eGui.innerHTML=`<div class="redColor">${differenceSign}${differenceTimeString}</div>`;
//         }else{
//           eGui.innerHTML=`<div class="sucessColor">${differenceSign}${differenceTimeString}</div>`;
//         }
          
      
//       return eGui;  
// }

function valueRenderTime(params){
        var op =document.createElement('div');

        var eGui = document.createElement('div');
        if(params.value ==="" || params.value ==="00:00:00"){
          eGui.innerHTML=`<div class="sucessColor"> - </div>`;
        }
        else if(params.data['Work_time_status'] === 'Over-time' || params.data['Work_time_status'] === 'complied' ){
          eGui.innerHTML=`<div class="sucessColor">${params.value}</div>`;
        }else{
          eGui.innerHTML=`<div class="redColor">${params.value}</div>`;
        }
          
      
      return eGui;  
}



function valueRenderTimeExcel(params){

        const actualWorkHoursMs = timeStringToMilliseconds(params.actual_work_hours);
const planWorkHoursMs = timeStringToMilliseconds(params.plan_work_hours);

const differenceMs = actualWorkHoursMs - planWorkHoursMs;
const differenceTimeString = millisecondsToTimeString(Math.abs(differenceMs));
const differenceSign = differenceMs >= 0 ? '' : '-';

        


      return `${differenceSign}${differenceTimeString}`;  
}
 

  function AGTablePlanActual(data){    
      

        let arr;
        let date='';

        arr=<div className={"keyAGTableSmallPlan keyAGTableSmallPlanHeader"}><div className="ag-theme-alpine if cell-size-40" style={{height:475,maxHeight:475 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={60}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          <AgGridColumn
              field="work_day"
              headerName={"Work Date"}
              valueFormatter={(params)=>params.value?moment(params.value.replaceAll(" GMT","")).format('DD-MM-YYYY'):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />

            <AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="worker_id"
              headerName={"Worker ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
           <AgGridColumn
              field="agency"
              headerName={"Agency"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
 <AgGridColumn
              field="department"
              headerName={"Department"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
           <AgGridColumn
              field="function"
              headerName={"Function"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />

          <AgGridColumn
              field="plan_check_in"
              headerName={"Plan Check In"}
              valueFormatter={(params)=>params.value?moment(params.value.replaceAll(" GMT","")).format('HH:mm'):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="actual_check_in"
              headerName={"Actual Check In"}
              valueFormatter={(params)=>params.value?moment(params.value.replaceAll(" GMT","")).format('HH:mm'):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="plan_check_out"
              headerName={"Plan Check Out"}
              valueFormatter={(params)=>params.value?moment(params.value.replaceAll(" GMT","")).format('HH:mm'):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="actual_check_out"
              headerName={"Actual Check Out"}
              valueFormatter={(params)=>params.value?moment(params.value.replaceAll(" GMT","")).format('HH:mm'):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
                    <AgGridColumn
              field="plan_checkin_status"
              headerName={"Plan Check-In Status"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="plan_checkout_status"
              headerName={"Plan Check-Out Status"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
                    <AgGridColumn
              field="plan_work_hours"
              headerName={"Plan Work Hours"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="actual_work_hours"
              headerName={"Actual Work Hours"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="over_time"
              headerName={"Time"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              
              cellRenderer= {(params)=>valueRenderTime(params)}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
                    <AgGridColumn
              field="Work_time_status"
              headerName={"Status"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
        
 
      </AgGridReact>
      </div></div>
      
      return arr
    
    }




    
     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
    <div className="App">
    <ToastContainer/>
    <Row>
      <Col lg="12"><h4>Attendance Report:  <span style={{color:"#ef5e8c"}}>Time</span></h4></Col>
      
    </Row>
      {AGTablePlanActual(filterData)}

    </div>:""
    )

}