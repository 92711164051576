import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getFopReportFilter, getReportByLocaionTime } from "../../actionMethods/actionMethods";

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getAttendanceTime,getRegularEmployeeMap } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";
// import DummyAll from './dummyAll.json'



const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function LocationTime (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const indonesiaTimeZone = 'Asia/Jakarta';  
    const [resultData,SetResultData] = useState([])
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");

      
  const [loader,SetLoader]= useState(true);


const columns = [
  { headerName: 'Name',title:"Name", dataIndex:"name", field: 'name' },
  { headerName: 'Worker ID',title:"Worker ID",dataIndex:"worker_id", field: 'worker_id' },
  { headerName: 'Agency',title:"Agency",dataIndex:"agency", field: 'agency' },
];

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }


const fetchWorkerLocation = async (location_serial,requestBody) => {
  const response = await getReportByLocaionTime(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date,requestBody.presence,location_serial,requestBody.slug).then(res=>res.data);
  // const data = await response.json();
  return response;
};


const mergeWorkerData = (data) => {
  // Add debugging
  console.log("Input data type:", typeof data);
  console.log("Is array?", Array.isArray(data));
  console.log("Data:", data);
  
  const mergedData = {};
  
  if (!Array.isArray(data)) {
    throw new Error("Input data is not an array",data);
  }

  const allLocations = Object.keys(data.reduce((acc, locationObj) => Object.assign(acc, locationObj), {}));

  data.forEach(locationObj => {
    Object.keys(locationObj).forEach(location => {
      const workers = locationObj[location];

      workers.forEach(worker => {
        const { worker_id, name, tag_serial, presence, interactions, time } = worker;

        if (!mergedData[worker_id]) {
          mergedData[worker_id] = {
            name,
            tag_serial,
            worker_id,
            worker_type: "daily",
            total:0
            
          };

          // Initialize all locations with default " - " values
          allLocations.forEach(loc => {
            mergedData[worker_id][`${loc}_presence`] = " - ";
            mergedData[worker_id][`${loc}_time`] = " - ";
            mergedData[worker_id][`${loc}_interactions`] = " - ";
          });
        }
        if(interactions){
          mergedData[worker_id]['total'] = mergedData[worker_id]['total'] + interactions;
        }
      
        mergedData[worker_id][`${location}_presence`] = presence || " - ";
        mergedData[worker_id][`${location}_time`] = time || " - ";
        mergedData[worker_id][`${location}_interactions`] = interactions || " - ";

      });
    });
  });

  return Object.values(mergedData);
};

function convertSecondsToHHMMSS(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad hours, minutes, and seconds with leading zeros if needed
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

// Example usage:
const result = convertSecondsToHHMMSS(3270);
console.log(result); // Output: 00:54:30



useEffect(()=>{
  let reportHead=[]
async function filterResultHandler(){
    try{
        if(props.filterActive){
          let requestBody = props.filterPassData;
            SetLoader(true);
          if(props.filterPassData.slug==="all"){
            const results = await getReportByLocaionTime(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date,requestBody.presence,"",requestBody.slug).then(res=>res.data);
            // const results = DummyAll.data;
            console.log("result in all before",results);
            const formatted= mergeWorkerData([results]);
            SetResultData(formatted)
            console.log("result in all",results,formatted);
            
          }
          else if(props.filterPassData.slug==="multiple"){
            console.log()
            const results = await getReportByLocaionTime(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date,requestBody.presence,requestBody.location,requestBody.slug).then(res=>res.data);
            
            const formatted= mergeWorkerData([results]);
            SetResultData(formatted)
            console.log("result in all",results,formatted);
          }
          else{
              const locationData = {};
              
                

                props.filterPassData.location.forEach(serial => {
                    locationData[serial] = [];
                  });
                  
            // Fetch data for each location_serial concurrently
            const results = await Promise.all(
              props.filterPassData.location.map(serial => fetchWorkerLocation(serial,props.filterPassData))
            );
            
            // Merge the API responses into the respective location_serial array
            results.forEach((result, index) => {
              locationData[props.filterPassData.location[index]] = result;
            });

            const formatted= mergeWorkerData(results);

            SetResultData(formatted)    
            console.log("results in individual",results,formatted);
          }

        // getReportByLocaionTime(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date,requestBody.presence,requestBody.slug)

          SetLoader(false);
          }
    }catch(error) {
            console.log("error",error)
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);


useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(resultData);
  }
},[props.exportEnable,resultData])

 const generateExcelFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    
        await generateSheet(workbook, "Report By Location",data ,imageId);
    

let locName = props.filterPassData.location.length > 1?props.filterPassData.location.length + " Location":props.filterPassData.location.join(",")

  const fileName = 'ReportByLocation '+locName+" "+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

  const generateSheet = async (workbook, sheetName, data, logoID) => {
  // Add a new worksheet
  const worksheet = workbook.addWorksheet(sheetName);

  // Add logo
  worksheet.addImage(logoID, {
    tl: { col: 0, row: 1 },
    ext: { width: 153, height: 34 },
  });

  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.mergeCells('A1:B4');
  worksheet.addRow([]);

  // Add date row
  worksheet.addRow(['Date', moment(props.filterPassData.date).format('YYYY-MM-DD')])
    .getCell(2).font = { bold: true };

  // Add report by location row
  worksheet.addRow(['Report By Location', "Time"])
    .getCell(2).font = { bold: true };

  // Add site row
  worksheet.addRow(['Site', userDetails.org_name])
    .getCell(2).font = { bold: true };

  // Add 'Location Selected' row with multiple location names
  const locationRow = worksheet.addRow([
    'Location Selected', 
    props.filterPassData.locationAr.map(el => el.location_name).join(", ")
  ]);
  
  locationRow.getCell(2).font = { bold: true };

  // Set the row height for 'Location Selected' row to accommodate the text
  locationRow.height = 30; // You can adjust this value based on the content length

  worksheet.addRow([]);
  worksheet.addRow([]);

  const tableData = data.map(item => [
    item.name,
    item.worker_id,
    item.tag_serial,
    convertSecondsToHHMMSS(item.total),
    ...props.filterPassData.location.flatMap(el => [
      item[`${el}_presence`],
      item[`${el}_time`]
    ])
  ]);

  const columns = [{ name: 'Name' }, { name: 'Worker ID' }, { name: 'Tag Serial' }, { name: 'Total Time' }];
  for (let i = 0; i < props.filterPassData.location.length; i++) {
    let locName = props.filterPassData.locationAr.find(el => el.tag_serial === props.filterPassData.location[i])?.location_name;
    columns.push({ name: `${locName} Time (%)` }, { name: `${locName} Time (hr)` });
  }

  worksheet.addTable({
    name: sheetName.replaceAll(' ', '_'),
    ref: `A12`,
    columns: columns,
    rows: tableData,
  });

  const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));

  // Width adjustment for columns, skipping the 'Location Selected' column (column index 1)
  worksheet.columns.forEach((column, index) => {
    if (index !== 1) { // Skip the 'Location Selected' column
      const lengths = column.values.map(v => v ? v.toString().length : 1);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength + 2;
    }
  });

  worksheet.getTable(sheetName.replaceAll(' ', '_')).commit();
  table.commit();
};


  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};


function timeStringToMilliseconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return ((hours * 60 + minutes) * 60 + seconds) * 1000;
}

function millisecondsToTimeString(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}



function valueRenderTime(params){
        var op =document.createElement('div');

        var eGui = document.createElement('div');
        if(params.value ==="" || params.value ==="00:00:00"){
          eGui.innerHTML=`<div class="sucessColor"> - </div>`;
        }
        else if(params.data['Work_time_status'] === 'Over-time' || params.data['Work_time_status'] === 'complied' ){
          eGui.innerHTML=`<div class="sucessColor">${params.value}</div>`;
        }else{
          eGui.innerHTML=`<div class="redColor">${params.value}</div>`;
        }
          
      
      return eGui;  
}



function valueRenderTimeExcel(params){

        const actualWorkHoursMs = timeStringToMilliseconds(params.actual_work_hours);
const planWorkHoursMs = timeStringToMilliseconds(params.plan_work_hours);

const differenceMs = actualWorkHoursMs - planWorkHoursMs;
const differenceTimeString = millisecondsToTimeString(Math.abs(differenceMs));
const differenceSign = differenceMs >= 0 ? '' : '-';

        


      return `${differenceSign}${differenceTimeString}`;  
}
 

  function AGTablePlanActual(data){    
      

        let arr;
        let date='';

        let arr2 = [];
        for(let i =0;i<props.filterPassData.location.length; i++){
          let locName = props.filterPassData.locationAr.find(el=>el.tag_serial === props.filterPassData.location[i])?.location_name
        
          arr2.push(
                <AgGridColumn
                  field={`${props.filterPassData.location[i]}_presence`}
                  headerName={`${locName} Time (%)`}
                  valueFormatter={(params)=>params.value && params.value!=" - " ?params.value+"%":"-"}
                  cellClass={'textCapitalize textCap'}
                  flex={1}
                  minWidth={100}
              />,
              <AgGridColumn
                  field={`${props.filterPassData.location[i]}_time`}
                  headerName={`${locName} Time (hr)`}
                  valueFormatter={(params)=>params.value && params.value!=" - "?params.value.toLowerCase():"-"}
                  cellClass={'textCapitalize textCap'}
                  flex={1}
                  minWidth={100}
              />


          )
        }

        arr=<div className={"keyAGTableSmallPlan keyAGTableSmallPlanHeader"}><div className="ag-theme-alpine if cell-size-40" style={{height:475,maxHeight:475 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={60}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >


            <AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
              minWidth={120}
          />
          <AgGridColumn
              field="worker_id"
              headerName={"Worker ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
              minWidth={100}
          />
           <AgGridColumn
              field="tag_serial"
              headerName={"Tag Serial"}
              valueFormatter={(params)=>params.value?params.value.toUpperCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
              minWidth={100}
          />
                     <AgGridColumn
              field="total"
              headerName={"Total Time"}
              valueFormatter={(params)=>params.value?convertSecondsToHHMMSS(params.value):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
              minWidth={100}
          />
          
{arr2}

        
 
      </AgGridReact>
      </div></div>
      
      return arr
    
    }




    
     return (props.filterActive?
      loader? 
       <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
    <div className="App">
    <ToastContainer/>
    <Row>
      <Col lg="12"><h4>Location Report:  <span style={{color:"#ef5e8c"}}>Time</span></h4></Col>
      
    </Row>
      {AGTablePlanActual(resultData)}

    </div>:""
    )

}