import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getChatGPTRecommendation, getFopReportFilter, getOverallAttendanceReport } from "../../actionMethods/actionMethods";

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getAttendanceTime } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";
import ReactHighcharts from "react-highcharts";
import Scrollbars from "react-custom-scrollbars";
import {ReactComponent as EyeIcon} from '../../../assets/images/eye.svg'
import { Button, Modal } from 'antd';

const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function AttendanceUseCases (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [activeTab,SetActiveTab] =useState('percent')
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const [chartData,SetChartData] = useState([]);
    const [chartData2,SetChartData2] = useState([]);
    const [recommendation1,SetRecommendation1] = useState([])
    const [recommendation1Loader,SetRecommendation1Loader]= useState(false);
    const [recommendation2Loader,SetRecommendation2Loader]= useState(false);
    const [recommendation2,SetRecommendation2] = useState([])
    const [open, setOpen] = React.useState(false);
    const [activeRecommendation,SetActiveRecommendation]= useState('');

    
    const [performanceAll,SetPerformanceAll] = useState([]);
    const [performanceThreeshold,SetPerformanceThreeshold] = useState([]);

    

    const indonesiaTimeZone = 'Asia/Jakarta';  
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
      const [config,SetConfig]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:250
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 6);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            gridLineWidth:0,
            labels: {
                useHTML:true,
                formatter: function () {
                        return this.value                         
                }
            },

            tickAmount:6,


        },
        tooltip: {
            shared: true,
            visible: true // Set tooltip always visible
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });
      
      const [config2,SetConfig2]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:250
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 6);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            gridLineWidth:0,
            
            min:0,
                        tickAmount:6,
            labels: {
      formatter: function () {
        let seconds = this.value;
        // 2- Extract hours:
        const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
        seconds = seconds % 3600; // seconds remaining after extracting hours
        // 3- Extract minutes:
        const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
        // 4- Keep only seconds not extracted to minutes:
        seconds = seconds % 60;

        let time = hours > 0 ? `${hours}h` : `${minutes}m`;
        if (time === "1h 0m 0s") {
          time = "1hr";
        }
        return time;
      }
    },            


        },

        tooltip: {
    useHTML: true,
    shared: true,
    formatter: function () {
      let tooltip = `<b>${this.x}</b><br/>`;
      this.points.forEach(point => {
        let seconds = point.y;
        // 2- Extract hours:
        const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
        seconds = seconds % 3600; // seconds remaining after extracting hours
        // 3- Extract minutes:
        const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
        // 4- Keep only seconds not extracted to minutes:
        seconds = seconds % 60;

        let time = hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : `${minutes}m ${seconds}s`;
        if (time === "1h 0m 0s") {
          time = "1hr";
        }
        tooltip += `<span style="color:${point.series.color}">${point.series.name}</span>: <b>${time}</b><br/>`;
      });
      return tooltip;
    }
  },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });
      
  const [loader,SetLoader]= useState(true);


const columns = [
  { headerName: 'Name',title:"Name", dataIndex:"name", field: 'name' },
  { headerName: 'Worker ID',title:"Worker ID",dataIndex:"worker_id", field: 'worker_id' },
  { headerName: 'Agency',title:"Agency",dataIndex:"agency", field: 'agency' },
];

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

  const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]    

  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };

function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}


   function formatNumberWithAbbreviation(number) {
      const billion = 1e9;
      const million = 1e6;
      const thousand = 1e3;

      if (number >= billion) {
        return (number / billion).toFixed(2) + 'B';
      } else if (number >= million) {
        return (number / million).toFixed(2) + 'M';
      } else if (number >= thousand) {
        return (number / thousand).toFixed(2) + 'k';
      } else {
        return number.toString();
      }
}
function getDateFromDayNumber(year, dayNumber) {
  const startDate = new Date(year, 0, 1); // January 1st of the given year
  startDate.setDate(startDate.getDate() + dayNumber - 1); // Subtract 1 because the 1st day is already counted
  return moment(startDate).format("DD MMM YY");
}

const formatComplianceAndPayrollData = (apiData,type) => {


  const complianceData = [];
  const payrollData = [];

  for (const day in apiData.data) {
    let dayName = day

    const dayData = apiData.data[day];
    if(type==="day"){
          // dayName =   moment(`01-01-${moment(props.filterPassData.start_date).format('YYYY')}`).add(parseInt(day.replaceAll('day ',"")),'days').format("DD MMM YY");
          dayName =  getDateFromDayNumber(moment(props.filterPassData.start_date).format('YYYY'),parseInt(day.replaceAll('day ',"")));
        }else if(type==="month"){
          dayName =  moment(`01-01-${moment(props.filterPassData.start_date).format('YYYY')}`).add(parseInt(day.replaceAll('month ',"")   - 1),'month').format("MMMM")
        }

    
    const date = dayName; 
    complianceData.push({
      date: date, // replace with actual date conversion if needed
      actual_count:dayData.total_actual_count,
      plan_count:dayData.total_planned_count,
      on_time:dayData.check_in_ontime,
      on_time_percentage:dayData.check_in_ontime_percentage,
      late: dayData.check_in_late,
      late_percentage: dayData.check_in_late_percentage,
      absent: dayData.absent,
      absent_percentage: dayData.absent_percentage,
      unexpected: dayData.unexpected_check_in,
      unexpected_percentage: dayData.unexpected_check_in_percentage,
      shift_change: dayData.shift_changes,
      shift_change_percentage: dayData.shift_changes_percentage,
      non_checkout: dayData.employees_non_checkout_count,
      non_checkout_percentage: dayData.employees_non_checkout_count_percentage,
    });

    payrollData.push({
      date: date, // replace with actual date conversion if needed
      overtime: dayData.total_over_time,
      undertime: dayData.total_under_time,
      undertime_loss: formatNumberWithAbbreviation(dayData.undertime_loss),
      overtime_pay: formatNumberWithAbbreviation(dayData.total_overtime_cost),
    });
  }

  return {
    compliance: complianceData,
    payroll: payrollData,
  };
};

const convertTimeToSeconds = (timeString) => {
  const timeParts = timeString.replaceAll(" days",":").split(':');

  let days = 0, hours = 0, minutes = 0, seconds = 0;

  if (timeParts.length === 4) { // e.g., "7 days 12:44:34"
    days = parseInt(timeParts[0].replaceAll(" days","").replaceAll(" week","").replaceAll(" mpnth",""));
    hours =  timeParts[1];
    minutes =  timeParts[2];
    seconds =  timeParts[3];

  } else if (timeParts.length === 3) { // e.g., "0:00:00" or "1 days 14:29:50"
    
    hours =  timeParts[0];
    minutes =  timeParts[1];
    seconds =  timeParts[2];
  } else {
    hours =  timeParts[0];
    minutes =  timeParts[1];
    seconds =  timeParts[2];
  }

  

  return parseInt( (days * 86400)) + parseInt((hours * 3600)) + parseInt((minutes * 60)) + parseInt(seconds);
};

useEffect(()=>{
  let reportHead=[]
  
async function filterResultHandler(){
    try{
        if(props.filterActive){
            SetLoader(true);
          let requestBody = props.filterPassData;
         
          let resp =    await getOverallAttendanceReport(userSession,org_id,sub_org_id,getDateFormat(props.filterPassData.start_date),getDateFormat(props.filterPassData.end_date),props.filterPassData.threeshold,props.filterPassData.reportBy);
          let data = formatComplianceAndPayrollData(resp,props.filterPassData.reportBy).compliance;
          let data2 = formatComplianceAndPayrollData(resp,props.filterPassData.reportBy).payroll
         
            if(props.filterPassData.reportBy =="day"){
              data = data.sort((a,b)=>new Date(a.date) - new Date(b.date));
              data2 = data2.sort((a,b)=>new Date(a.date) - new Date(b.date));
            }


            let series= [];


            series.push({name:"Late Comers",data:data.map(el=>el.late_percentage)})
            series.push({name:"Absent",data:data.map(el=>el.absent_percentage)})
            series.push({name:"Unexpected",data:data.map(el=>el.unexpected_percentage)})
            series.push({name:"Shift Change",data:data.map(el=>el.shift_change_percentage)});
            series.push({name:"Non Checkout",data:data.map(el=>el.non_checkout_percentage)});
            let cat = data.map(el=>{
              if(props.filterPassData.reportBy =="day"){
                return moment(el.date).isValid()? moment(el.date).format("DD MMM"):el.date
              }else{
                  return el.date
              }
            });
            
           
            
            SetChartData({cat:cat,series:series});
            SetPerformanceAll(data);

            let series2 = [];
            
            
            series2.push({name:"Overtime",data:data2.map(el=>convertTimeToSeconds(el.overtime) )})
            series2.push({name:"UnderTime",data:data2.map(el=>convertTimeToSeconds(el.undertime))});
            
            
            SetPerformanceThreeshold(data2);
            

             let newConfig={...config};
             newConfig.xAxis.categories=cat;
             newConfig.series = series; 
             console.log("newConfig",newConfig,cat)
             SetConfig(newConfig);

            let newConfig2 = {...config2}
            newConfig2.xAxis.categories=cat;
            newConfig2.series = series2;
            SetChartData2({cat:cat,series:series2});
            SetConfig2(newConfig2);

          SetLoader(false);






          
        }
              

    }catch(error) {
            
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);


  function loadRecomend1(message){
    
    getChatGPTRecommendation('Hi gpt, this is data of attendace behaviour of workers in the organisation from '+moment(props.filterPassData.start_date).format('MMM DD')+' to '+moment(props.filterPassData.end_date).format('MMM DD')+'  data below'+JSON.stringify(message)+"please share result as only takeways point. So, What are takeaway trends?").then(res=>{
     
      let output = res.replaceAll("- ","").replaceAll("1. ","").replaceAll("2. ","").replaceAll("3. ","").replaceAll("4. ","").replaceAll("5. ","").replaceAll("6. ","").replaceAll("7. ","").replaceAll("8. ","").replaceAll("9. ","").replaceAll("10. ","").replaceAll("11. ","").replaceAll("12. ","").replaceAll("13. ","").replaceAll("14. ","").replaceAll("15. ","").split("\n").filter(el=>el!="");
     
      SetRecommendation1(output);
      SetRecommendation1Loader(false);
    })
  }

  

  function loadRecomend2(message){
    
    getChatGPTRecommendation('Hi gpt, this is data of attendace behaviour of workers in the organisation from '+moment(props.filterPassData.start_date).format('MMM DD')+' to '+moment(props.filterPassData.end_date).format('MMM DD')+'  data below'+JSON.stringify(message)+"please share result as only takeways point. So, What are takeaway trends?").then(res=>{
     
      let output = res.replaceAll("- ","").replaceAll("1. ","").replaceAll("2. ","").replaceAll("3. ","").replaceAll("4. ","").replaceAll("5. ","").replaceAll("6. ","").replaceAll("7. ","").replaceAll("8. ","").replaceAll("9. ","").replaceAll("10. ","").replaceAll("11. ","").replaceAll("12. ","").replaceAll("13. ","").replaceAll("14. ","").replaceAll("15. ","").split("\n").filter(el=>el!="");
     
      SetRecommendation2(output);
      SetRecommendation2Loader(false);
    })
  }


useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(filterData);
  }
},[props.exportEnable,filterData])

 const generateExcelFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    
        await generateSheet(workbook, "Attendance Time",data ,imageId);
    
  const fileName = 'AttendanceUseCasesReport '+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName,data, logoID) => {
    // Add a new worksheet

    
  };
  

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};

useEffect(()=>{

    let data =[...performanceAll];
    let series= [];
    
    if(activeTab == "count"){
      series.push({name:"Late Comers",data:data.map(el=>el.late)})
      series.push({name:"Absent",data:data.map(el=>el.absent)})
      series.push({name:"Unexpected",data:data.map(el=>el.unexpected)})
      series.push({name:"Shift Change",data:data.map(el=>el.shift_change)});
      series.push({name:"Non Checkout",data:data.map(el=>el.non_checkout)});
      let cat = data.map(el=>{
        if(props.filterPassData.reportBy =="day"){
            return moment(el.date).isValid()? moment(el.date).format("DD MMM"):el.date
        }else{
            return el.date
        }
      });
      console.log("cat",cat,series)
      SetChartData({cat:cat,series:series});
                   let newConfig={...config};
             newConfig.xAxis.categories=cat;
             if(newConfig.yAxis.max){
                 delete newConfig.yAxis.max;
             }
            
             newConfig.series = series; 
             SetConfig(newConfig);
    }else{
      series.push({name:"Late Comers",data:data.map(el=>el.late_percentage)})
      series.push({name:"Absent",data:data.map(el=>el.absent_percentage)})
      series.push({name:"Unexpected",data:data.map(el=>el.unexpected_percentage)})
      series.push({name:"Shift Change",data:data.map(el=>el.shift_change_percentage)});
      series.push({name:"Non Checkout",data:data.map(el=>el.non_checkout_percentage)});
      let cat = data.map(el=>{
        if(props.filterPassData.reportBy =="day"){
        return moment(el.date).isValid()? moment(el.date).format("DD MMM"):el.date
      }else{
          return el.date
      }
      });
     
      SetChartData({cat:cat,series:series});
                   let newConfig={...config};
             newConfig.xAxis.categories=cat;
             newConfig.series = series; 
             newConfig.yAxis.max=100;

             SetConfig(newConfig);
  
    }
    
},[activeTab,performanceAll])

function AGTableShift(data,active){    
        let arr;
        let date='';
        
        if(props.filterPassData.reportBy=="day"){
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }else{
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }


        if(active == 'count'){
        

        arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{minHeight:350,height:(data.length * 32) + 36,maxHeight:375 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          {date}
            <AgGridColumn 
              field="actual_count" 
              headerName="Actual Attendance" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
            <AgGridColumn 
              field="plan_count" 
              headerName="Plan Attendance" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
            <AgGridColumn 
              field="on_time" 
              headerName="On Time" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
          <AgGridColumn 
              field="late" 
              headerName="Late Comers" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />          
          <AgGridColumn 
              field="absent" 
              headerName="Absent" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
            <AgGridColumn 
              field="unexpected" 
              headerName="Unexpected" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
            <AgGridColumn 
              field="shift_change" 
              headerName="Shift Change" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              
              flex={1}
             ></AgGridColumn>
                         <AgGridColumn 
              field="non_checkout" 
              headerName="Non Checkout" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              
              flex={1}
             ></AgGridColumn>
 
      </AgGridReact>
      </div></div>

        }else{

                  arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{minHeight:350,height:(data.length * 32) + 36,maxHeight:375 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          {date}
            <AgGridColumn 
              field="actual_count" 
              headerName="Actual Attendance " 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
            <AgGridColumn 
              field="plan_count" 
              headerName="Plan Attendance" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
            <AgGridColumn 
              field="on_time_percentage" 
              headerName="On Time" 
              valueFormatter={(params) =>params.value?`${params.value}%`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
          <AgGridColumn 
              field="late_percentage" 
              headerName="Late Comers" 
              valueFormatter={(params) =>params.value?`${params.value}%`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />          
          <AgGridColumn 
              field="absent_percentage" 
              headerName="Absent" 
              valueFormatter={(params) =>params.value?`${params.value}%`:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
            <AgGridColumn 
              field="unexpected_percentage" 
              headerName="Unexpected" 
              valueFormatter={(params) =>params.value?`${params.value}%`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
            <AgGridColumn 
              field="shift_change_percentage" 
              headerName="Shift Change" 
              valueFormatter={(params) =>params.value?`${params.value}%`:"-"}    
              
              flex={1}
             ></AgGridColumn>
                         <AgGridColumn 
              field="non_checkout_percentage" 
              headerName="Non Checkout" 
              valueFormatter={(params) =>params.value?`${params.value}%`:"-"}    
              
              flex={1}
             ></AgGridColumn>
 
      </AgGridReact>
      </div></div>

        }

      
      return arr
    
    }


    function AGTableAttendanceOvertime(data,append){    
     

        let arr;
        let date='';

        if(props.filterPassData.reportBy=="day"){
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=> params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }else{
          date= <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }

        arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{minHeight:350,height:(data.length * 32) + 36,maxHeight:375 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
              headerHeight={20}
              defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          {date}
          
          
          <AgGridColumn 
              field="overtime" 
              headerName="Over Time" 
              valueFormatter={(params) =>params.value?`${params.value} ${append}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
            <AgGridColumn 
              field="undertime" 
              headerName="Under Time" 
              valueFormatter={(params) =>params.value?`${params.value} ${append}`:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
          <AgGridColumn 
              field="undertime_loss" 
              headerName="UnderTime Loss" 
              valueFormatter={(params) =>params.value?`${params.value} ${append}`:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />

            <AgGridColumn 
              field="overtime_pay" 
              headerName="Overtime Pay" 
              valueFormatter={(params) =>params.value?`${params.value} ${append}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    
    }
  



    
     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:

    <div className="App">
        
<ToastContainer/>

    <Row>
      <Col lg="8" className="">
        <div className="leftTitle">
          <h4 style={{fontSize:16}}>Attendance Compliance <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>({props.filterPassData.reportBy})</span></h4>
        </div>  
        <div className="rightTitle">
      <div className="buttonFilterArea">
      <button onClick={() => SetActiveTab('percent')} className={activeTab === 'percent' ? 'buttonChartFilter buttonChartFilterActive button btn' : 'buttonChartFilter button btn'}>Percent </button>
            <button onClick={() => SetActiveTab('count')} className={activeTab === 'count' ? 'buttonChartFilter buttonChartFilterActive button btn' : 'buttonChartFilter button btn'} > Count</button>
      </div>
      </div>
      </Col>
      <Col lg={4}></Col>
      </Row>

      <Row>

                    <Col lg={8} className="someStyleHeadline" style={{display:"table-cell"}}>
                                                {AGTableShift(performanceAll,activeTab)}

                    </Col>
      <Col lg={4}>
                     <div className="reccomentTop"> <div className="recommendBox" style={{border:"1px solid #ddd"}}>
                        <div className="reccomentHeader"><h4>Insights  <span className="recommendationEye" style={{display:recommendation1.length?"inline-block":"none",margin:0}} onClick={()=>{SetActiveRecommendation('recommendation1');setOpen(true)}}><EyeIcon/></span> <span className="reloadRecommend" onClick={()=> {SetRecommendation1Loader(true);loadRecomend1(performanceAll); }}>{recommendation1.length > 0? "Reload":"Load"}</span></h4></div>                       
                         
                        <div className="recommentContent" >
                           {/* <div dangerouslySetInnerHTML={{ __html: recommendation1}} />  */}
                           <Scrollbars style={{height:100}}>
                                                         {recommendation1Loader?<div style={{textAlign:"center"}}><img src={spinnerLoader} width={"70px"} /></div>:
                           <ul style={{listStyle:"none"}}>
                            {recommendation1.map((el,index)=>{
                              return <li key={"reccomend "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
                            })}
                           </ul>}
                           </Scrollbars>

                        </div>
                       </div>
                       </div>
        <ReactHighcharts config={config}/>
      </Col>
      
    </Row>
      <hr />

    <Row>
      <Col lg="12" className=""><h4 style={{fontSize:16}}>Payroll Over time / Undertime   <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>({props.filterPassData.reportBy})</span></h4></Col>
      
                    <Col lg={8} className="someStyleHeadline" style={{display:"table-cell"}}>

                       {AGTableAttendanceOvertime(performanceThreeshold,"")}

                    </Col>
                    <Col lg={4}>
                      
                      <div className="reccomentTop"> <div className="recommendBox" style={{border:"1px solid #ddd"}}>
                        <div className="reccomentHeader"><h4>Insights <span className="recommendationEye"  onClick={()=>{SetActiveRecommendation('recommendation2');setOpen(true)}} style={{display:recommendation2.length?"inline-block":"none"}}><EyeIcon/></span> <span className="reloadRecommend" onClick={()=> {SetRecommendation2Loader(true);loadRecomend2(performanceThreeshold); }}>{recommendation2.length > 0? "Reload":"Load"}</span> </h4></div>
                        
                        <div className="recommentContent">

                          <Scrollbars style={{height:100}}>
                              {recommendation2Loader?<div style={{textAlign:"center"}}><img src={spinnerLoader} width={"70px"} /></div>:
                           <ul style={{listStyle:"none"}}>
                            {recommendation2.map((el,index)=>{
                              return <li key={"reccomend2 "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
                            })}
                           </ul>}
                           </Scrollbars>

                        </div>
                       </div>
                       </div>


        <ReactHighcharts config={config2}/>
      </Col>
<Modal
        title={"Insights"}
        footer={null}

        open={open}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <Scrollbars style={{height:400}}>
        <ul>
          
          {activeRecommendation=="recommendation1"?recommendation1.map((el,index)=>{
            return <li key={"recommendModa "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
          }):
          recommendation2.map((el,index)=>{
            return <li key={"recommendModal2 "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
          })
      }
      </ul>
      </Scrollbars>

        
        
      </Modal>      
    </Row>




    </div>:""
    )

}