import React from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../dashboard/components';
import ThreatWatch from '../dashboard/components/threatWatch';

import SiteMangementList from '../siteManagement/components/index'
import SiteMangementView from '../siteManagement/components/SiteMangementView'
import SiteViewDetails from '../siteManagement/components/details';
import LoginComponent from '../login/components/login';
import ManPowerMangementList from '../manPowerManagement/components';

import PrivateRoute from './privateRoute'
import EmployeeList from '../manPowerManagement/components/employeeList';
import EmployeeDetails from '../manPowerManagement/components/details';

import PolicyManagement from '../policyManagement/components';
import PolicyCompliancePeople from "../policyManagement/components/policyCompliance/people";
import PolicyComplianceTeam from "../policyManagement/components/policyCompliance/team";
import PolicyComplianceLocation from '../policyManagement/components/policyCompliance/location'
import PolicyDetail from '../policyManagement/components/detail/policyDetail';
import VisitorManagement from '../visitorManagement/components/index';

import VisitorDetails from '../visitorManagement/components/details/index';
import ProductivityDetails from '../productivity/componenets/detail/index'
import Simplr from '../employeeTracker/components/Simplr'

import FacilitiesTracker from '../realtimeTracker/components/index'

import ReportExport from '../report/componenets/index'
import CycleCount from '../cycleCount/componenets/index'

import ReactDOMServer from 'react-dom/server';

import MovementAnalyzer from '../employeeTracker/components/movementAnalyzer';
import HeatMap from '../heatMap/components/HeatMap';
import ReportOverview from '../report/componenets/ReportOverview';

import DashboardOverview  from '../dashboardOverview/components/index'
import ProductivitEmployee from '../productivity/componenets/index24Hour';
import ProductivitOld from '../productivity/componenets/indexOldLanding';
import CostManagement from '../costManagement/components/index'

import FacilityVideo from '../realtimeTracker/components/FacilityVideo';
import HeatmapFacility from '../realtimeTracker/components/heatmap';
import ReportHideFOP from '../report/componenets/indexHideFOP'
import FacilityVideo2 from '../realtimeTracker/components/FacilityVideoV2';
import ManpowerPlaning from '../report/componenets/ManpowerPlaning';
import FacilityMap from '../realtimeTracker/components/FacilityMap';
import ShiftManagement from '../shiftManagement/componenets/index'
import FacilityMapPleton from '../realtimeTracker/components/FacilityMapPleton';

const Routes = (props) => {
 
    return (<Switch>
            <Route
            exact
            path="/login"
            render={(routeProps) => <LoginComponent {...routeProps} {...props} />}
            />
        <PrivateRoute exact path='/dashboard' component={Dashboard} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}  />
        <PrivateRoute exact path='/site-list/view/:id' component={SiteViewDetails} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/site-list' component={SiteMangementList} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/site-management' component={SiteMangementView} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/manpower-management' component={ManPowerMangementList} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/manpower-management/employee-list' component={EmployeeList} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/manpower-management/employee-list/view/:id' component={EmployeeDetails} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/policy-management' component={PolicyManagement} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/policy-compliance-people/:id' component={PolicyCompliancePeople} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/policy-compliance-location/:id' component={PolicyComplianceLocation} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/policy-compliance-team/:id' component={PolicyComplianceTeam} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/policy-detail/:id' component={PolicyDetail} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/visitor-management' component={VisitorManagement} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/visitor-management/view/:id' component={VisitorDetails} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/> 
        <PrivateRoute exact path='/productivity' component={ProductivitEmployee} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList} />
        <PrivateRoute exact path='/productivity-old' component={ProductivitOld} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        <PrivateRoute exact path='/productivity/:employee/:id/:date' component={ProductivityDetails} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/productivity/:employee/:id' component={ProductivityDetails} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        <PrivateRoute exact path='/employee-tracker/:tag/:hour' component={Simplr} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        <PrivateRoute exact path='/employee-tracker' component={Simplr} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList} />
        <PrivateRoute exact path='/movement-analyzer' component={MovementAnalyzer} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList} />
      
        <PrivateRoute exact path='/geo-map-management' component={FacilitiesTracker} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/heatmap-2' component={HeatMap} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/heatmap' component={HeatmapFacility} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/facility-video' component={FacilityVideo} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/facility-map' component={FacilityMap} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/facility-map-pleton' component={FacilityMapPleton} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/facility-video-2' component={FacilityVideo2} role={props.role} pages={props.pages} workday={props.workday} weekDays={props.weekDays} holiday={props.holidayList}/>
        <PrivateRoute exact path='/cost-management' component={CostManagement} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        
        <PrivateRoute exact path='/report-overview' component={ReportOverview} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        <PrivateRoute exact path='/report' component={ReportExport} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        <PrivateRoute exact path='/cycle-count' component={CycleCount} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        <PrivateRoute exact path='/dashboard-overview' component={DashboardOverview} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>

        <PrivateRoute exact path='/report-2' component={ReportHideFOP} role={props.role} pages={props.pages} workday={props.workday} weekDays={props.weekDays} holiday={props.holidayList}/>
        <PrivateRoute exact path='/manpower-planing' component={ManpowerPlaning} role={props.role} pages={props.pages} workday={props.workday} weekDays={props.weekDays} holiday={props.holidayList}/>
        <PrivateRoute exact path='/shift-management' component={ShiftManagement} role={props.role} pages={props.pages} workday={props.workday} weekDays={props.weekDays} holiday={props.holidayList}/>
        <PrivateRoute exact path='/' component={Dashboard} role={props.role} pages={props.pages} weekDays={props.weekDays} workday={props.workday} holiday={props.holidayList}/>
        
    </Switch>)
}

export default Routes
