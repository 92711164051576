
import dashboardDefaultIcon from '../assets/traceplusImages/dahboard-default.svg'
import dashboardActiveIcon from '../assets/traceplusImages/dahboard-active.svg'



import SiteManagementDefaultIcon from '../assets/traceplusImages/dashboardDefault.svg'
import SiteManagementActiveIcon from '../assets/traceplusImages/dashboardActive.svg'

import ManPowerManagementDefaultIcon from '../assets/traceplusImages/siteManagementDefault.svg'
import ManPowerManagementActiveIcon from '../assets/traceplusImages/siteManagementActive.svg'

import PolicyManagementDefaultIcon from '../assets/traceplusImages/manpowerManagementDefault.svg'
import PolicyManagementActiveIcon from '../assets/traceplusImages/manpowerManagementActive.svg'

import VisitorManagementDefaultIcon from '../assets/traceplusImages/visitor-default.svg'
import VisitorManagementActiveIcon from '../assets/traceplusImages/vistor-active.svg'

import ProductivityIcon from '../assets/images/chart-column.svg'
import ProductivityIconActive from '../assets/images/chart-column-active.svg'

import GeoIcon from '../assets/images/map-location-regular.svg'
import GeoIconActive from '../assets/images/map-location-active.svg'

import ExportIcon from '../assets/images/file-export.svg'
import ExportIconActive from '../assets/images/file-export-active.svg'

import ClockIcon from '../assets/images/clock.svg'
import ClockIconActive from '../assets/images/clockActive.svg'

import CostIcon from '../assets/images/money-bill-wave.svg'
import CostIconActive from '../assets/images/money-bill-wave-active.svg'

import DeviceIcon from '../assets/images/router.svg'
import DeviceIconActive from '../assets/images/routerActive.svg'
import facilityMap from '../assets/images/video.svg'
import facilityMapActive from '../assets/images/videoActive.svg'

import FacilityMapPleton from '../assets/images/video-plus.svg'
import FacilityMapPletonActive from '../assets/images/video-plusActive.svg'

export const sidebarMenuItems = [
    {
        "title": "Dashboard",
        "defaultIcon": dashboardDefaultIcon,
        "activeIcon":dashboardActiveIcon,
        "paths":"/dashboard-overview",
        "tooltip":"Dashboard",
        "status":"active",
    },
    {
        "title": "Productivity Management",
        "defaultIcon": ProductivityIcon,
        "activeIcon":ProductivityIconActive,
        "paths":"/productivity",
        "tooltip":"Productivity Management",
        "status":"active",
    },
    {
        "title": "Site Management",
        "defaultIcon": SiteManagementDefaultIcon,
        "activeIcon":SiteManagementActiveIcon,
        "paths":"/site-list",
        "tooltip":"Site Management",
        "status":"active",
    },
    {
        "title": "Manpower Management",
        "defaultIcon": ManPowerManagementDefaultIcon,
        "activeIcon":ManPowerManagementActiveIcon,
        "paths":"/manpower-management",
        "tooltip":"Manpower Management",
        "status":"active",
    },
          {
        "title": "Shift Management",
        "defaultIcon": ClockIcon,
        "activeIcon":ClockIconActive,
        "paths":"/shift-management",
        "tooltip":"Shift Management",
        "status":"active",
    },
    {
        "title": "Geo Map Management",
        "defaultIcon": GeoIcon,
        "activeIcon":GeoIconActive,
        "paths":"/employee-tracker",
        "tooltip":"Geo Map",
        "status":"active",
    },
    {
        "title": "Cycle Count",
        "defaultIcon": ClockIcon,
        "activeIcon":ClockIconActive,
        "paths":"/cycle-count",
        "tooltip":"Report",
        "status":"active",
    },
    {
        "title": "Health Management",
        "defaultIcon": dashboardDefaultIcon,
        "activeIcon":dashboardActiveIcon,
        "paths":"/dashboard",
        "tooltip":"Health Management",
        "status":"active",
    },
    
    {
        "title": "Policy Management",
        "defaultIcon": PolicyManagementDefaultIcon,
        "activeIcon":PolicyManagementActiveIcon,
        "paths":"/policy-management",
        "tooltip":"Policy Management",
        "status":"active",
    },
    {
        "title": "Visitor Management",
        "defaultIcon": VisitorManagementDefaultIcon,
        "activeIcon":VisitorManagementActiveIcon,
        "paths":"/visitor-management",
        "tooltip":"Visitor Management",
        "status":"active",
    },
        {
        "title": "Cost Management",
        "defaultIcon": CostIcon,
        "activeIcon":CostIconActive,
        "paths":"/cost-management",
        "tooltip":"cost Management",
        "status":"active",
    },
        {
        "title": "Facility Map",
        "defaultIcon": facilityMap,
        "activeIcon":facilityMapActive,
        "paths":"/facility-map",
        "tooltip":"Facility Map",
        "status":"active",
    },
    {
        "title": "Facility Map Pleton",
        "defaultIcon": FacilityMapPleton,
        "activeIcon":FacilityMapPletonActive,
        "paths":"/facility-map-pleton",
        "tooltip":"Facility Map Pleton",
        "status":"active",
    },

    {
        "title": "Manpower Planning",
        "defaultIcon": ManPowerManagementDefaultIcon,
        "activeIcon":ManPowerManagementActiveIcon,
        "paths":"/manpower-planing",
        "tooltip":"Manpower Planning",
        "status":"active",
    },
      
    

    {
        "title": "Reports",
        "defaultIcon": ExportIcon,
        "activeIcon":ExportIconActive,
        "paths":"/report",
        "tooltip":"Report",
        "status":"active",
    }, 

    // {
    //     "title": "Employee Tracker",
    //     "defaultIcon": ProductivityIcon,
    //     "activeIcon":ProductivityIconActive,
    //     "paths":"/employee-tracker",
    //     "tooltip":"Productivity Management",
    //     "status":"active",
    // },
    // {
    //     "title": "Facilities Tracker",
    //     "defaultIcon": ProductivityIcon,
    //     "activeIcon":ProductivityIconActive,
    //     "paths":"/facility-tracker",
    //     "tooltip":"Productivity Management",
    //     "status":"active",
    // },

]